import { getMindboxDeviceUuid } from './get-mindbox-device-uuid';
import { getCookie } from './get-cookie';

type RequestOptions = {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';
  headers: Record<string, string>;
  referrerPolicy?: ReferrerPolicy;
  credentials?: RequestCredentials;
  body?: any;
};

const { SOCIAL_API_URL, URANIA_API_URL } = (window as any).CONSTANTS;
export const SOCIAL_API = `${SOCIAL_API_URL}/api/v23`;
export const URANIA_API = `${URANIA_API_URL}/api/v14`;

const getApi = (api: string) => ({
  get: get(api),
  post: post(api),
  put: put(api),
  delete: _delete(api),
});

function get(
  api: string
): <T>(url: string, fullResponse?: boolean) => Promise<T> {
  return <T>(url: string, fullResponse?: boolean) =>
    fetch(api + url, getRequestOptions('GET')).then((r) =>
      handleResponse<T>(r, fullResponse)
    );
}

function post(api: string): <T, U>(url: string, body: T) => Promise<U> {
  return <T, U>(url: string, body: T) =>
    fetch(api + url, getRequestOptions<T>('POST', body)).then((r) =>
      handleResponse<U>(r)
    );
}

function put(
  api: string
): <T, U>(url: string, body?: T) => Promise<U | undefined> {
  return <T, U>(url: string, body?: T) =>
    fetch(api + url, getRequestOptions<T>('PUT', body)).then((r) =>
      handleResponse<U>(r)
    );
}

function _delete(api: string): (url: string) => Promise<Response> {
  return (url: string) => fetch(api + url, getRequestOptions('DELETE'));
}

function uploadImage<T>(image: File): Promise<T> {
  const formData = new FormData();
  formData.set('image', image);

  const option = { ...getRequestOptions('POST') };
  option.body = formData;
  if (option.headers) {
    delete option.headers['Content-Type'];
  }

  return fetch(`${SOCIAL_API}/images/`, option).then((r) =>
    handleResponse<T>(r)
  );
}

// helper functions
export function handleResponse<T>(
  response: Response,
  fullResponse?: boolean
): Promise<T> {
  return response
    .json()
    .then((data) => {
      if (!response.ok || data.status !== 'ok') {
        const errorText = data?.error?.code || response.statusText;
        throw errorText;
      }
      return fullResponse ? data : data.data;
    })
    .catch((err) => {
      throw String(err);
    });
}

export function getRequestOptions<T>(
  method: RequestOptions['method'],
  body?: T
) {
  const requestOptions: RequestOptions = {
    method,
    credentials: 'include',
    // По умолчанию, fetch не отправляет полный Referer при cross-origin
    // запросах. Но у нас он используется, например для аналитики. Поэтому
    // меняем политику на передачу полного Referer'a, кроме HTTP запросов,
    // которых у нас по идее быть не должно
    referrerPolicy: 'no-referrer-when-downgrade',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Куку x_csrf подставляет соц.платформа
  // чтобы приложение могло запрашивать/менять данные
  const csrf = getCookie('x_csrf');
  if (csrf) {
    requestOptions.headers['X-CSRFToken'] = csrf;
  }

  const mindboxDeviceUuid = getMindboxDeviceUuid();
  if (mindboxDeviceUuid) {
    requestOptions.headers['tj-device-id'] = mindboxDeviceUuid;
  }

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  return requestOptions;
}

export const socialApi = {
  ...getApi(SOCIAL_API),
  uploadImage,
};

export const uraniaApi = getApi(URANIA_API);

import { social } from '../adapters';
import type { ArticleStats, Resource } from '../types';

export default class ArticleStatsController {
  private statsPromise: Promise<void> | null = null;

  static current: ArticleStatsController;
  private constructor(
    private resource: Resource<ArticleStats>,
    private updateResource: (newValue: ArticleStats) => void
  ) {
    this.init();
  }

  async getStats(articleId: string) {
    if (!this.statsPromise) {
      this.statsPromise = this.getStatsPromise(articleId);
    }
    await this.statsPromise;
    this.statsPromise = null;
  }

  private async getStatsPromise(articleId: string) {
    const stats = await social.stats.get(articleId);
    this.updateResource({
      favoritesCount: stats.favorites_count,
      commentsCount: stats.comments_count,
      likes: stats.likes,
      dislikes: stats.dislikes,
    });
  }

  private init() {
    if (window.TJ_ARTICLE_META?.articleId) {
      this.getStats(window.TJ_ARTICLE_META.articleId);
    }
  }

  static initialize(
    resource: Resource<ArticleStats>,
    updateResource: (newValue: ArticleStats) => void
  ) {
    if (ArticleStatsController.current) {
      throw new Error('Already initialized');
    }

    ArticleStatsController.current = new ArticleStatsController(
      resource,
      updateResource
    );
  }
}

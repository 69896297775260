import type {
  ActionStatus,
  Profile,
  RecentSocialId,
  RequestLoginParams,
  Resource,
} from '../types';
import { social } from '../adapters';
import { present } from '../presentation';
import type { ModalState } from '../presentation/auth-popup/types/types';
import { buildLoginEvent } from '../events/login';
import type { LoginProviderName } from '../presentation/auth-popup/components/social-networks/providers';
import { waitForClosing } from '../presentation/auth-popup/components/social-networks/do-social-auth';

const recentSocialIdKeyName = 'tj-shared-core-recent-social-id';

export default class AuthController {
  static current: AuthController;
  private constructor(
    private resource: Resource<Profile>,
    private updateResource: (newValue: Profile) => void,
    private updateRecentSocialId: (newValue: RecentSocialId) => void
  ) {
    this.init();
  }

  // Карусель авторизации в новой вкладке браузера после переезда на новый на домен
  private async checkPrevAuth() {
    const domain = 'journal.tbank.ru';
    const alreadyTriedToAuth = 'tj-shared-core-already-tried-auth';
    const socialApiUrlCheckPrevAuth =
      'https://social.journal.tinkoff.ru/api/v26/account/login/session/';
    const windowClosingTimeout = 15 * 1000;

    // проверяем, находимся на новом домене или на старом,
    // если на старом, то выходим и идем по стандартному пути
    if (!window.location.hostname.includes(domain)) {
      return;
    }

    // проверяем, проходил ли уже пользователь по карусели авторизации
    // если проходил - пропускаем выходим из этого шага и отправляем дальше по стандартному пути
    const isChecked = localStorage.getItem(alreadyTriedToAuth);
    if (isChecked) {
      return;
    }

    // если не проходил: ставим ему значение в localStorage в true
    localStorage.setItem(alreadyTriedToAuth, 'true');

    try {
      // Открываем новую вкладку браузера
      const authWindow = window.open('about:blank', '_blank') || undefined;
      // Открываем адрес старой соцплатформы
      if (authWindow && !authWindow.closed) {
        authWindow.location.replace(socialApiUrlCheckPrevAuth);
      }

      // ждем пока вкладка не закроется или не истечет время ожидания
      await waitForClosing(authWindow, windowClosingTimeout);

      if (authWindow && !authWindow.closed) {
        authWindow.close();
      }
      await this.getProfile();
    } catch {}
  }

  async login(params: RequestLoginParams): Promise<ActionStatus> {
    if (!params?.pointOfContact) {
      throw new Error('Params pointOfContact is missing');
    }

    await this.checkPrevAuth();

    if (this.resource.get()) {
      // уже залогинены, возможно что-то обновляем
      return Promise.resolve('success');
    }

    // Динамически импортируем попап авторизации, чтобы грузить его только
    // в ситуациях когда он нужен
    const AuthPopup = (await import('../presentation/auth-popup/auth-popup'))
      .default;

    return new Promise((resolve) => {
      // дергаем слой presentation и показываем попап авторизации
      const unmount = present(AuthPopup, {
        onSuccess: async (provider) => {
          await this.getProfile();
          unmount();
          const e = buildLoginEvent(params.pointOfContact, provider);
          dispatchEvent(e);
          resolve('success');
        },
        onDismiss: async (provider: LoginProviderName, state?: ModalState) => {
          let status: ActionStatus = 'fail';
          // Если новый пользователь ввел проверочный код и дошел до заполнения имени и фото,
          // то при закрытии попапа, даже без ввода инфы, он является успешно залогиненным
          // Так же здесь добавлены кейсы, когда пользователь дошел до этапа мерджа аккаунтов и закрыл попап,
          // то он так же является успешно залогиненным
          const successStates = [
            'personal',
            'merge_accounts',
            'code_merge_accounts',
            'merge_accounts_success',
          ];

          if (state && successStates.includes(state)) {
            status = 'success';
            await this.getProfile();
            const e = buildLoginEvent(params.pointOfContact, provider);
            dispatchEvent(e);
          }
          if (status === 'fail' && params.onAuthCancel) {
            params.onAuthCancel();
          }
          unmount();
          resolve(status);
        },
        popup: {
          pointOfContact: params.pointOfContact,
          uiText: params.uiText,
          initialLoginValue: params.initialLoginValue,
          promocode: params.promocode,
        },
      });
    });
  }

  async logout() {
    return social.logout().then((status) => {
      this.updateResource(null);
      return status;
    });
  }

  async getProfile() {
    await social
      .profile()
      .then((p: any) => {
        if (p) {
          this.updateResource(p);

          const socialId = p.id;
          if (socialId) {
            localStorage.setItem(recentSocialIdKeyName, socialId);
            this.updateRecentSocialId(socialId);
          }
        }
      })
      .catch(() => this.updateResource(null));
  }

  private init() {
    // Получить закэшированные данные
    this.getProfile();

    const socialId = localStorage.getItem(recentSocialIdKeyName);
    if (socialId) {
      this.updateRecentSocialId(parseInt(socialId, 10));
    }
  }

  static initialize(
    resource: Resource<Profile>,
    updateResource: (newValue: Profile) => void,
    updateRecentSocialId: (newValue: RecentSocialId) => void
  ) {
    if (AuthController.current) {
      throw new Error('Already initialized');
    }
    AuthController.current = new AuthController(
      resource,
      updateResource,
      updateRecentSocialId
    );
  }
}

/* eslint-disable jest/no-mocks-import */
import socialAdapter from './social';
import uraniaAdapter from './urania';
import socialMockAdapter from './__mocks__/social';
import uraniaMockAdapter from './__mocks__/urania';

// При добавлении новых адаптеров, нужно написать алиас в webpack.config.js и тогда моки не попадут в продовую сборку
export const social = process.env.MOCK ? socialMockAdapter : socialAdapter;
export const urania = process.env.MOCK ? uraniaMockAdapter : uraniaAdapter;
/* eslint-enable jest/no-mocks-import */
